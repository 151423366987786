<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="editApi.isError"
      :api="editApi"
      :callbackReset="() => (editApi.isError = false)"
    ></AError>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col cols="12" sm="6">
          <h2>Move Student</h2>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <SelectField
                :value="classroomId"
                :field="selectField"
                @input="classroomId = $event"
              ></SelectField>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                :loading="editApi.isLoading"
                rounded
                color="primary"
                dark
                @click="submit()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import SelectField from "../../components/Form/SelectField.vue";
export default {
  components: {
    SelectField,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    selectField: {
      defaultValue: 8,
      name: "Classroom",
      options: [],
      optionText: "name",
      optionValue: "id",
    },
    valid: true,
    name: null,
    id: null,
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    classroomApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    classroomId: 8,
    mochiId: null,
  }),
  created() {

    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.grandparentId+"/classroom/" +
      this.$route.params.parentId +
      "/student/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.name = resp.name;
      this.mochiId = resp.mochiId;
      this.classroomId = resp.Classroom.id
      this.updateBreadcrumb(resp)
    };

    //EOC
    this.classroomApi.method = "GET";
    this.classroomApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/data/institution/"+ this.$route.params.grandparentId+"/classroom";

    this.classroomApi.callbackReset = () => {
      this.classroomApi.isLoading = true;
      this.classroomApi.isError = false;
    };

    this.classroomApi.callbackError = (e) => {
      this.classroomApi.isLoading = false;
      this.classroomApi.isError = true;
      this.classroomApi.error = e;
    };

    this.classroomApi.callbackSuccess = (resp) => {
      this.classroomApi.isLoading = false;
      this.selectField.options = resp;
    };
    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/classroom/" +
      this.$route.params.parentId +
      "/student/move";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = (resp) => {
      this.editApi.isLoading = false;
      this.$router.push({ name: 'PageEducatorInstitutionClassroomStudentRead', params: {
        id:this.$route.params.id, 
        parentId:  resp.classroomId,
        grandparentId:  this.$route.params.grandparentId, 
      } });
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.selectField.defaultValue = this.classroomId
    this.$api.fetch(this.api);
    this.$api.fetch(this.classroomApi);
  },
  watch: {
   
  },
  methods: {
    updateBreadcrumb(resp) {
      this.breadcrumbs.push({
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      });
      this.breadcrumbs.push({
        text: resp.Classroom.Institution.name,
        to: { name: "PageEducatorInstitutionDashboard",params:{id:this.$route.params.grandparentId} },
        exact: true,
      });
      this.breadcrumbs.push({
        text: 'Classrooms',
        to: { name: "PageEducatorInstitutionClassroom",params:{id:this.$route.params.grandparentId} },
        exact: true,
      });
      this.breadcrumbs.push({
        text: resp.Classroom.name,
        to: { name: "PageEducatorInstitutionClassroomDashboard" ,params:{id:this.$route.params.parentId,parentId:this.$route.params.grandparentId}},
        exact: true,
      });

      this.breadcrumbs.push({
      text: "Students",
      to: { name: "PageEducatorInstitutionClassroomStudent" ,params:{id:this.$route.params.parentId,parentId:this.$route.params.grandparentId} },
      exact: true,
    });
    this.breadcrumbs.push({
      text: resp.name,
      to: { name: "PageEducatorInstitutionClassroomStudentRead" ,params:{id:this.$route.params.id,parentId:this.$route.params.parentId,grandparentId:this.$route.params.grandparentId} },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "Move",
      to: { name: "PageEducatorInstitutionClassroomStudentMove" },
      exact: true,
    });
    },
    close() {
      this.$router.push({ name: 'PageEducatorInstitutionClassroomStudent', params: { parentId:  this.$route.params.grandparentId,id:  this.$route.params.parentId } });
    },
    next() {
      this.step++;
    },
    back() {
      this.step--;
    },
    submit() {
      let self = this;
      setTimeout(function () {
        if (self.$refs.formName.validate()) {
          self.editApi.params = {
            id: self.id,
            classroomId: self.classroomId,
          };
          self.$api.fetch(self.editApi);
        } else {
          self.editApi.callbackError("Please check the form.");
        }
      });
    },
    selectUserType(item) {
      this.mochiId = item.value;
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>